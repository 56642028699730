import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

function HomeHeroSection() {
  gsap.registerPlugin(ScrollTrigger);

  const heroVideo = useRef();
  const heroSection = useRef();

  // const [heroActive, setheroActive] = useState(0);

  const tl = useRef();
  const loadingValueText = useRef();

  useEffect(() => {
    const canvas = heroVideo.current;
    const loadingValue = loadingValueText.current;
    const context = canvas.getContext('2d');

    canvas.width = 1300;
    canvas.height = 731;

    const frameCount = 348;
    const currentFrame = (index) =>
      `/seq/hero-video${(index + 1).toString().padStart(3, '0')}.webp`;

    const images = [];
    const airpods = {
      frame: 0,
    };

    let imagesToLoad = frameCount;

    for (let i = 0; i < frameCount; i++) {
      const img = new Image();
      img.onload = onLoad;
      img.src = currentFrame(i);
      images.push(img);
    }

    function onLoad() {
      imagesToLoad--;
      this.onload = null;
      loadingValue.textContent = `${Math.round(
        ((frameCount - imagesToLoad) / frameCount) * 100,
      )}%`;

      if (imagesToLoad) {
        render();
        console.log(12121);
        gsap.set(canvas, { autoAlpha: 1 });
        gsap.to(loadingValueText.current, { autoAlpha: 0 });
      }
    }

    tl.current = gsap.timeline().to(airpods, {
      frame: frameCount - 1,
      snap: 'frame',
      scrollTrigger: {
        trigger: heroSection.current,
        start: '0 0',
        scrub: 0.5,
      },
      onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
    });

    images[0].onload = render;

    function render() {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(images[airpods.frame], 0, 0, 1300, 731);
    }
  }, [tl]);
  return (
    <>
      <div
        className="hero-loader bg-lightyellow fixed top-0 left-0 right-0 bottom-0 m-auto text-48 text-center font-600 text-primary flex justify-center items-center z-50"
        ref={loadingValueText}>
        0%
      </div>
      <div className="hero-video-scroll relative" ref={heroSection}>
        <div className="hero-video">
          <div className="container">
            <canvas ref={heroVideo} />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeHeroSection;
