import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Link } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import '../styles/homepage.scss';
import HomeHeroSection from '../components/HomeHeroSection/HomeHeroSection';

function IndexPage() {
  gsap.registerPlugin(ScrollTrigger);

  const [accordion, setAccordion] = useState(0);

  function accordionClick(value) {
    return () => setAccordion((state) => (state === value ? null : value));
  }

  const xrayParent = useRef();
  const xrayImg = useRef();
  const effipacUsage = useRef();

  const tl = useRef();

  useEffect(() => {
    const medIcon = gsap.utils.toArray('.single-med');
    medIcon.forEach((med) => {
      tl.current = gsap.timeline().to(med, {
        scrollTrigger: {
          start: '0 50%',
          end: '100% 0',
          trigger: '.meds-sticky',
          scrub: 0.5,
          // markers: true,
        },

        y: med.getAttribute('value'),
      });
    });

    tl.current = gsap.timeline().to(xrayImg.current, {
      scrollTrigger: {
        start: '10% 40%',
        end: '60% 30%',
        trigger: xrayParent.current,
        // markers: true,
        scrub: 0.5,
      },

      clipPath: 'inset(0 0 0% 0)',
    });

    tl.current = gsap.timeline().to('.single-usage', {
      scrollTrigger: {
        start: '0 40%',
        end: '50% 30%',
        trigger: effipacUsage.current,
        scrub: 0.5,
        // markers: true,
      },
      delay: 1,
      stagger: 1,
      clipPath: 'inset(0% 0% 0% 0%)',
    });

    // gsap.set('.testimonial-single', { yPercent: 100, autoAlpha: 0 });

    // ScrollTrigger.batch('.testimonial-single', {
    //   start: '0% 80%',
    //   end: '40% 50%',
    //   // markers: true,
    //   trigger: '.testimonial-block',
    //   onEnter: (elements) => {
    //     tl.current = gsap.timeline().to(elements, {
    //       yPercent: 0,
    //       autoAlpha: 1,
    //       stagger: {
    //         each: 0.1,
    //         from: 'random',
    //       },
    //     });
    //   },
    //   onLeaveBack: (elements) => {
    //     tl.current = gsap.timeline().to(elements, {
    //       yPercent: 100,
    //       autoAlpha: 0,
    //     });
    //   },
    // });

    // gsap.set('.single-trusted-by', { y: 300, autoAlpha: 0 });

    // ScrollTrigger.batch('.single-trusted-by', {
    //   start: '0% 80%',
    //   end: '40% 50%',
    //   // markers: true,
    //   trigger: '.trusted-by',
    //   onEnter: (elements) => {
    //     tl.current = gsap.timeline().to(elements, {
    //       y: 0,
    //       autoAlpha: 1,
    //       stagger: {
    //         each: 0.1,
    //         from: 'random',
    //       },
    //     });
    //   },
    //   onLeaveBack: (elements) => {
    //     tl.current = gsap.timeline().to(elements, {
    //       y: 300,
    //       autoAlpha: 0,
    //     });
    //   },
    // });
  }, []);

  // const parallax = (event) => {
  //   document.querySelectorAll('.mouse-parallax').forEach((shift) => {
  //     const position = shift.getAttribute('value');
  //     const x = (window.innerWidth - event.pageX * position) / 90;
  //     const y = (window.innerHeight - event.pageY * position) / 90;

  //     shift.style.transform = `translateX(${x}px) translateY(${y + 40}px)`;
  //   });
  // };

  // useEffect(() => {
  //   if (window.innerWidth > 1200) {
  //     document.addEventListener('mousemove', parallax);
  //   }
  // }, []);

  return (
    <Layout>
      <SEO title="Effipac" />

      <section className="home-hero">
        <div className="container">
          <div className="hero-content text-center">
            <h1 className="font-medium text-primary mb-3">
              Your Pills, Our Priority
            </h1>
            <p className="mx-auto max-w-[790px] mb-4">
              EFFIPAC is a system where we buy medicines for the patients as per
              their prescription, organize them into individual pouches as per
              the dosage, and deliver them to their doorstep.
            </p>

            <Link to="/process" className="button">
              How it works
            </Link>
          </div>
        </div>
        <HomeHeroSection />
      </section>

      <section className="section-gap ">
        <div className="container ">
          <div className="text-center mb-6">
            <h4 className="tophead">Features &amp; Benefits</h4>
            <h2 className="heading mb-4 mx-auto max-w-[800px]">
              Simplifying medications as packed doses
            </h2>

            <Link className="button" to="/features">
              Explore features
            </Link>
          </div>
        </div>

        <div className="container">
          <div className="sticky-product-features">
            <div className="row ">
              {[
                {
                  id: 0,
                  icon: '/img/simple.svg',
                  title: 'Simple',
                  description:
                    'Get your medicines packed in a personalized way as per your daily intake.',
                },
                {
                  id: 1,
                  icon: '/img/easy.svg',
                  title: 'Easy',
                  description:
                    'Large labels with clear indications remind you of which medicines to take and when.',
                },
                {
                  id: 2,
                  icon: '/img/convenient.svg',
                  title: 'Convenient',
                  description:
                    'Medicines are sorted and packed in pouches in order of day and time to avoid any errors.',
                },
              ].map(({ id, icon, title, description }) => (
                <div key={id} className="col col-4">
                  <div className="feature-single feature-text py-2 md:py-4">
                    <div className="feature-icon w-[88px] mb-1">
                      <img src={icon} alt={title} />
                    </div>
                    <h3 className="heading-smallest w-full text-primary mb-2 ">
                      {title}
                    </h3>

                    <p>{description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="section-gap !pt-0 relative overflow-hidden meds-sticky">
        <div className=" hidden lg:block">
          <div
            className="single-med absolute left-0 right-0 top-0 mx-auto w-[250px] text-center"
            value="-100">
            <img
              src="/img/med-top-center.png"
              className="inline-block"
              alt=""
            />
          </div>
          <div
            className="single-med absolute left-4 top-1/3 text-center mouse-parallax"
            value="-300">
            <img
              src="/img/med-left-center.png"
              className="inline-block"
              alt=""
            />
          </div>
          <div
            className="single-med absolute right-6  bottom-1/4 text-center mouse-parallax"
            value="-350">
            <img
              src="/img/med-bottom-right.png"
              className="inline-block"
              alt=""
            />
          </div>
        </div>
        <div className="section-pad bg-lightgreen !pb-0 reltaive">
          <div className="meds-pouches absolute left-0 right-0 mx-auto top-0 text-center hidden lg:block pointer-events-none">
            <img src="/img/med-pouches.png" className="inline-block" alt="" />
          </div>
          <div className="container">
            <div className="text-center">
              <h4 className="tophead !text-primary">Know your pouch</h4>
              <h2 className="heading mb-3 mx-auto max-w-[800px]">
                Easy identification, No confusion
              </h2>

              <p className="mx-auto max-w-[750px] mb-3">
                Our pouches containing the medicines are neatly and clearly
                labelled with all the necessary information to avoid confusion.
                Each pouch comprises only those medicines that are to be taken
                at a particular time of the day.
              </p>
              <Link to="/about#about-effipac" className="button">
                Learn more
              </Link>
            </div>
          </div>
          <div className="platform text-center">
            <img src="/img/platform-body.png" className="inline-block" alt="" />
          </div>
        </div>
      </section>

      <section className="section-gap">
        <div className="container">
          <div className="text-center">
            <h2 className="heading mx-auto max-w-[800px]">
              Your monthly medicines in a box
            </h2>
          </div>
        </div>

        <div
          className="xray-block text-center relative mx-2 md:mx-0 my-5 md:my-0"
          ref={xrayParent}>
          <img src="/img/xray.png" className="inline-block" alt="" />

          <div
            className="xray-overlay absolute left-0 right-0 top-0 mx-auto text-center"
            ref={xrayImg}>
            <img src="/img/xray-1.png" className="inline-block" alt="" />
          </div>
        </div>

        <div className="container">
          <div className="text-center">
            <p className="mx-auto  max-w-[800px] ">
              We have developed and installed an automated system to organize
              your medicines as per the dosage. Just send us your prescription,
              and we will organize all the medicines for the entire month and
              deliver the box to your doorstep.
            </p>
          </div>
        </div>
      </section>

      <section className="section-gap">
        <div className="effipac-usage relative" ref={effipacUsage}>
          <div className="sticky top-0">
            <div className="single-usage h-screen relative z-0">
              <h2 className="heading">
                Convenient <br /> enough
              </h2>
              <img
                src="/img/morning.jpg"
                className="object-cover w-full h-full hidden md:block"
                alt=""
              />
              <img
                src="/img/morning-mobile.jpg"
                className="object-cover w-full h-full block md:hidden"
                alt=""
              />
            </div>
            <div className="single-usage h-screen absolute top-0 left-0 right-0 z-10">
              <h2 className="heading">
                To keep by
                <br /> Your side
              </h2>
              <img
                src="/img/afternoon.jpg"
                className="object-cover w-full h-full  hidden md:block"
                alt=""
              />
              <img
                src="/img/afternoon-mobile.jpg"
                className="object-cover w-full h-full block md:hidden"
                alt=""
              />
            </div>
            <div className="single-usage h-screen absolute top-0 left-0 right-0 z-20">
              <h2 className="heading">
                Anywhere, <br /> Anytime!
              </h2>
              <img
                src="/img/night.jpg"
                className="object-cover w-full h-full  hidden md:block"
                alt=""
              />
              <img
                src="/img/night-mobile.jpg"
                className="object-cover w-full h-full block md:hidden"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <div className="section-gap" id="faq">
        <div className="container">
          <h2 className="heading mb-6 text-center">
            Frequently asked questions
          </h2>
          <div className="row">
            <div className="col-md col-8 offset-2">
              <div className="faq-main">
                {[
                  {
                    id: 0,
                    question: 'What exactly is EFFIPAC?',
                    answer: (
                      <p>
                        EFFIPAC is a system where we buy medicines for the
                        patients as per their prescription, organize them into
                        individual pouches as per the dosage, and deliver them
                        to their doorstep. With this, we aim to make the
                        ‘medicine-taking’ process convenient for patients.
                      </p>
                    ),
                  },
                  {
                    id: 1,
                    question: 'How is EFFIPAC beneficial?',
                    answer: (
                      <p>
                        The patient will always take the right medicine at the
                        right time. Patients don’t have to remember complex
                        medicine names and medication error is completely
                        avoided because of pre-sorted medicines.
                      </p>
                    ),
                  },
                  {
                    id: 2,
                    question: 'To whom is EFFIPAC useful?',
                    answer: (
                      <p>
                        Any person, of any age who suffers from chronic illness
                        and has to take more than 4 medicines a day for a longer
                        time period.
                      </p>
                    ),
                  },
                  {
                    id: 3,
                    question: 'How can you sign up for our service?',
                    answer: (
                      <p>
                        Give us a call on 88 0909 4343 and provide us with the
                        necessary details like Name, Contact No., Residential
                        Address, Medical Condition, and Medication History.
                      </p>
                    ),
                  },
                  {
                    id: 4,
                    question:
                      'In which areas of Pune is our service available?',
                    answer: (
                      <p>
                        We are open to providing our service in any part of
                        Pune.
                      </p>
                    ),
                  },
                  {
                    id: 5,
                    question:
                      'How can you send the patient’s prescription to us?',
                    answer: (
                      <p>
                        At present, we use WhatsApp as the medium. You can send
                        us a photo of the prescription through WhatsApp on 88
                        0909 4343.
                      </p>
                    ),
                  },
                  {
                    id: 6,
                    question:
                      'In how many days will EFFIPAC be delivered to your home?',
                    answer: (
                      <p>
                        We understand the importance of taking timely medicines
                        and hence deliver them within 24 hrs of receiving the
                        prescription.
                      </p>
                    ),
                  },
                  {
                    id: 7,
                    question: 'From which pharmacies do we buy the medicines?',
                    answer: (
                      <p>
                        We have collaborated with a few verified pharmacies in
                        Pune from where we procure the medicines.
                      </p>
                    ),
                  },
                  {
                    id: 8,
                    question:
                      'What if your medication is revised in the middle of the month?',
                    answer: (
                      <p>
                        We will provide you with a refund for the remaining days
                        and repack your medicines for the rest of the month as
                        per the new prescription.
                      </p>
                    ),
                  },
                ].map(({ id, question, answer }) => (
                  <div
                    key={id}
                    className={`accrodion-single relative bg-lightgreen rounded-lg mb-2 ${
                      accordion === id ? 'open' : ''
                    }`}>
                    <button
                      type="button"
                      onClick={accordionClick(id)}
                      className="text-left p-3 w-full relative flex justify-between items-center text-18 md:text-[20px] font-medium text-primary">
                      <pan className="flex-1">{question}</pan>
                      <span className="accordion-indicator"></span>
                    </button>

                    <div
                      className={`accordion-content p-3 pt-0 ${
                        accordion === id ? 'fadein block' : 'hidden'
                      }`}>
                      {answer}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section-gap">
        <div className="container">
          <div className="text-center">
            <h4 className="tophead">Testimonials</h4>
            <h2 className="heading mb-6">What Our Clients Are Saying</h2>
          </div>

          <div className="row testimonial-block">
            {[
              {
                id: 0,
                testimonial: (
                  <>
                    Effipac provides medicines in sequence and hence there is no
                    tension. tracking medicines consumption is easy and very
                    convenient to take daily medicines without mistakes
                  </>
                ),
                testimonialAuthor: 'Ms. Renu Datta',
              },
              {
                id: 1,
                testimonial: (
                  <>
                    It was very easy for my mom to take medicines and she could
                    understand very well. She is very happy with this service.
                  </>
                ),
                testimonialAuthor: 'Mr. Abhishek Kaushik',
              },
              {
                id: 2,
                testimonial: (
                  <>
                    It was a great experience and the process helped me a lot.
                    It is a great initiative for the patients to keep going.
                  </>
                ),
                testimonialAuthor: 'Niljagi Babu',
              },
            ].map(({ id, testimonial, testimonialAuthor }) => (
              <div key={id} className="col col-4">
                <div className="testimonial-single rounded-[24px] overflow-hidden mb-3">
                  <div className="p-4 bg-white">
                    <div className="quote w-4 mb-4">
                      <svg
                        className="w-full h-auto"
                        preserveAspectRatio="xMidYMid meet"
                        data-bbox="18 32 164 135.886"
                        viewBox="18 32 164 135.886"
                        height="200"
                        width="200">
                        <path
                          d="M18 32h74.971v60.914c-1.098 35.403-20.114 60.394-57.047 74.972L19.629 138.21c22.812-9.372 34.218-24.47 34.218-45.296H18V32zm89.029 0H182v60.914c-1.098 35.403-20.114 60.394-57.048 74.972l-16.294-29.676c22.812-9.372 34.218-24.47 34.218-45.296h-35.847V32z"
                          fill="#126656"
                          fillRule="evenodd"
                          data-color="1"
                        />
                      </svg>
                    </div>

                    <p className="text-primary mb-0">{testimonial}</p>
                  </div>

                  <div className="testimonial-author bg-lightgreen px-4 py-2">
                    <p className="mb-0 text-primary">{testimonialAuthor}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="section-gap relative z-0 trusted-by">
        <div className="container">
          <div className="text-center">
            <h2 className="heading mb-6">Supported by</h2>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 flex-wrap gap-3">
            {[
              {
                id: 0,
                logoName: 'startup-india.png',
              },
              {
                id: 0,
                logoName: 'govt-of-india.png',
              },
              {
                id: 0,
                logoName: 'venture-center.png',
              },
              {
                id: 0,
                logoName: 'iit-mandi.png',
              },
            ].map(({ id, logoName }) => (
              <div
                key={id}
                className="single-trusted-by rounded-[16px] bg-white text-center flex items-center justify-center h-[100px] md:h-[150px]">
                <img
                  src={`/img/${logoName}`}
                  alt={logoName}
                  className="inline-block max-w-[100px] md:max-w-[150px]"
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="section-gap !pb-0 relative z-10">
        <div className="section-pad bg-lightgreen">
          <div className="container">
            <div className="text-center">
              <h2 className="heading mb-4">
                A solution to manage your medicines in an efficient way
              </h2>
              <p className="text-center mx-auto max-w-[750px] mb-4">
                EFFIPAC has redesigned every part of the medication handling
                experience so that it is Simple, Easy and Convenient.
              </p>
              <Link to="/process" className="button">
                Get started
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
